.auto-1440 {
  height: 0.6rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.auto-1440 .icon_notice {
  font-size: 0.23rem;
  margin-right: 0.1rem;
}

.auto-1440 .label {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.auto-1440 .label span {
  font-size: 0.18rem;
  color: #00ffc0;
  font-weight: bold;
}

.newswiper {
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.swiper {
  flex: 1;
  height: 0.6rem;
}

.swiper-slide {
  display: flex;
  height: 0.6rem !important;
  align-items: center;
}

.swiper-slide a {
  flex: 1;
  color: #fff;
  font-weight: bold;
}

.swiper-slide a:hover {
  color: #00ffc0;
}

.swiper-slide .time {
  flex-shrink: 0;
  color: rgba(255, 255, 255, 0.502);
}