.ind_nav {
  display: flex;
}

.ind_nav .item {
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 0.5rem;
}

.ind_nav .item:not(:last-of-type) {
  margin-right: 0.5rem;
}

.ind_nav .item a {
  display: block;
  padding: 0.7rem 1rem;
  position: relative;
}

.ind_nav .item a::before {
  content: '';
  position: absolute;
  left: 0.2rem;
  top: 0.2rem;
  bottom: 0.2rem;
  right: 0.2rem;
  border: 0.05rem solid #00ffc0;
  opacity: 0;
  transition: 0.3s;
}

.ind_nav .item a:hover::before {
  opacity: 1;
}

.ind_nav .item .center {
  margin-bottom: 0.4rem;
}

.ind_nav .item .cn {
  font-size: 0.36rem;
}

.ind_nav .item .des {
  color: #ffffff;
  line-height: 1.667;
  height: 11.669em;
  margin-bottom: 0.2rem;
}

.ind_nav .item .dot8 {
  -webkit-line-clamp: 7;
}

.ind_nav .item .more {
  text-align: center;
  color: #fff;
}