.ind_study {
  padding: 0.5rem 0;
}

.title_wrap {
  display: flex;
  margin-bottom: 0.4rem;
}

.title_wrap .title {
  flex-shrink: 0;
  width: calc(33.33% + 0.1rem);
}

.title_wrap .des {
  font-size: 0.18rem;
  color: #333333;
  line-height: 1.667;
  text-align: justify;
}

.swiper-slide {
  height: 4.65rem;
  background-color: #fafafb;
  border-radius: 0.1rem;
  overflow: hidden;
  position: relative;
}

.list {
  overflow: hidden;
}

.swiper-pagination {
  position: relative;
  padding-top: 0.6rem;
}

/deep/ .swiper-pagination-bullet {
  vertical-align: top;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 0.04rem;
  cursor: pointer;
  transition: 0.3s;
  background-color: #00ffc0;
  opacity: 0.5;
}

/deep/ .swiper-pagination-bullet-active {
  width: 0.3rem;
  background-color: #00ffc0;
}

.info {
  padding: 0.5rem 0.55rem;
}

.info .icon {
  width: 1.9rem;
  height: 1.9rem;
  position: relative;
  margin: 0 auto 0.25rem;
}

.info .icon img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.info .icon::before {
  content: '';
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: #dcfdf4;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.info .txt {
  text-align: center;
}

.info .txt h2 {
  font-size: 0.28rem;
  color: #000000;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.info .txt .des {
  font-size: 0.18rem;
  color: #555555;
  line-height: 1.667;
  height: 5.001em;
}

.links {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 0.1rem;
  background-color: rgba(17, 38, 65, 0.8);
  opacity: 0;
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  padding: 0.4rem;
}

.links a {
  height: 0.8rem;
  border: 0.02rem solid rgba(255, 255, 255, 0.2);
  width: calc(50% - 0.2rem);
  text-align: center;
  margin: 0.05rem 0;
  color: #fff;
  line-height: 1.33;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.05rem;
  transition: 0.3s;
}

.links a:hover {
  border-color: #00ffc0;
  background-color: #00ffc0;
  color: #000;
}

.swiper-slide:hover .links {
  opacity: 1;
}