:deep(.swiper-button-prev) {
  left: 0.3rem;
  color: #00ffc0;
  transform: translateY(-0.6rem);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.3rem 0.2rem;
  transition: 0.3s;
}

:deep(.swiper-button-prev):hover {
  background-color: #000;
}

:deep(.swiper-button-prev)::after {
  font-size: 0.24rem;
  line-height: 1;
}

:deep(.swiper-button-next) {
  right: 0.3rem;
  color: #00ffc0;
  transform: translateY(-0.6rem);
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.3rem 0.2rem;
  transition: 0.3s;
}

:deep(.swiper-button-next)::after {
  font-size: 0.24rem;
  line-height: 1;
}

:deep(.swiper-button-next):hover {
  background-color: #000;
}