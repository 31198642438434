/deep/ .ant-modal {
  width: 10rem !important;
}

.loginDialog /deep/ .ant-modal-body {
  padding: 0.6rem 0.8rem 0.5rem;
  min-height: 6.1rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.loginDialog /deep/ .ant-form-item-children {
  display: flex;
}

.title {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 0.45rem;
  flex-shrink: 0;
}

.title span {
  padding-bottom: 0.26rem;
  line-height: 1;
  position: relative;
  font-size: 0.32rem;
  color: #333333;
}

.title span::before {
  content: '';
  width: 1rem;
  height: 0.05rem;
  background-color: #00ffc0;
  border-radius: 0.02rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.qrcode-btn {
  width: 0.8rem;
  position: absolute;
  left: 0.05rem;
  top: 0.05rem;
}

.qrcode-btn img {
  max-width: 100%;
  display: block;
  cursor: pointer;
}

.qrcode-btn::before {
  content: '';
  width: 0;
  height: 0;
  border: 0.565685rem solid transparent;
  position: absolute;
  right: 0;
  top: 0;
  border-top-color: #fff;
  transform-origin: right top;
  transform: rotateZ(-45deg);
  z-index: 2;
}

@media screen and (min-width: 1800px /*no*/) and (max-height: 800px /*no*/) {
  /deep/ .ant-modal-content {
    transform: scale(0.8);
  }
}

.dialog-container {
  font-size: 0.18rem;
  line-height: 1.667;
  color: #333333;
  flex: 1;
}

.dialog-footer {
  flex-shrink: 0;
  text-align: center;
  font-size: 0.16rem;
  color: #888888;
}

/deep/ .ant-spin-nested-loading {
  flex: 1;
}

.ant-carousel /deep/ .custom-slick-arrow {
  width: 0.25rem;
  height: 0.25rem;
  font-size: 0.3rem;
  color: #666;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  transition: .3s;
}

.ant-carousel /deep/ .custom-slick-arrow:hover {
  color: #333 !important;
}

.ant-carousel /deep/ .custom-slick-arrow:before {
  display: none;
}

.ant-carousel /deep/ .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel /deep/ .slick-slide h3 {
  color: #fff;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.swiper-button-prev,
.swiper-button-next {
  font-size: 0.4rem;
  color: #00ffc0;
  transition: .3s;
}

.swiper-button-prev {
  left: -0.5rem;
}

.swiper-button-next {
  right: -0.5rem;
}

/deep/ .swiper-container {
  position: static;
  overflow: hidden;
}

/deep/ .swiper-container .swiper-pagination-bullet {
  vertical-align: top;
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 0.04rem;
  background-color: #eee;
  opacity: 1;
  cursor: pointer;
  transition: .3s;
}

/deep/ .swiper-container:hover .swiper-button-prev {
  left: 0.1rem;
}

/deep/ .swiper-container:hover .swiper-button-next {
  right: 0.1rem;
}

/deep/ .swiper-container .swiper-pagination-bullet-active {
  width: 0.3rem;
  background-color: #00ffc0;
}

/deep/ .swiper-container .swiper-pagination {
  font-size: 0;
  z-index: 20;
}

/deep/ .ant-spin-nested-loading {
  display: flex;
  flex-direction: column;
}

/deep/ .ant-spin-nested-loading .ant-spin-container {
  flex: 1;
  margin-bottom: 0.3rem;
  overflow: hidden;
}

/deep/ .ant-checkbox-input:focus + .ant-checkbox-inner,
/deep/ .ant-checkbox:focus::after,
/deep/ .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
/deep/ .ant-checkbox-wrapper:hover .ant-checkbox-inner,
/deep/ .ant-checkbox-wrapper:focus .ant-checkbox-inner,
/deep/ .ant-checkbox-checked::after,
/deep/ .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #00ffc0;
}

/deep/ .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00ffc0;
  border-color: #00ffc0;
  box-shadow: unset;
}