.cn {
  font-size: 0.48rem;
  font-weight: bold;
  line-height: 1;
  position: relative;
  z-index: 2;
  letter-spacing: 0.2rem;
}

.en {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  opacity: 0.3;
  margin-top: -0.3rem;
}

.left .cn {
  color: #000;
}

.left .en {
  color: #79f9d9;
}

.center {
  text-align: center;
}

.center .cn {
  color: #fff;
}

.center .en {
  color: #fff;
  opacity: 0.05;
}